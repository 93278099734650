import React, { useEffect } from 'react';
import classNames from 'classnames';

import { Helmet } from 'react-helmet';
import CartFlyout from '../../components/CartFlyout';
import Footer from '../Footer';
import Header from '../Header';

import { parseJson } from '../../utils';

const ns = `layout-default`;

const DefaultLayout = ({ children, location }) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	useEffect(() => {
		window.OptanonWrapper = () => { };
	}, []);

	const isShopifyConsentTrackingApiEnabled = (
		Boolean(process.env.SHOPIFY_ENABLE_CONSENT_TRACKING_API)
	);

	const isOsanoEnabled = (
		process.env.GATSBY_OSANO_ACCOUNT_ID && process.env.GATSBY_OSANO_COOKIE_CONSENT_CONFIGURATION_ID
	);

	return (
		<>
			<Helmet>
				{isShopifyConsentTrackingApiEnabled &&
					<script
						type={'text/javascript'}
						src={'https://cdn.shopify.com/shopifycloud/consent-tracking-api/v0.1/consent-tracking-api.js'}
						data-flag-initialize-shopify-privacy-api
						onload={()=>{console.log('Consent Initialization: Shopify Script Tag Loaded');}}
					/>
				}
				{isShopifyConsentTrackingApiEnabled &&
					<script type={'text/javascript'} data-flag-initialize-shopify-privacy-api>{`
						console.log('Consent Initialization: Inline Shopify Script');
						const setTrackingConsent = window.Shopify.customerPrivacy.setTrackingConsent;
						window.Shopify.customerPrivacy.setTrackingConsent = (function(consentOptions, callback) {
							console.log('Consent: Shopify Wrapped Script: Is setting with...', {consentOptions});
							return setTrackingConsent({
								...consentOptions,
								headlessStorefront: true,
								checkoutRootDomain: '${process.env.GATSBY_DOMAIN_CHECKOUT}',
								storefrontRootDomain: '${process.env.GATSBY_DOMAIN_STOREFRONT}',
								storefrontAccessToken: '${process.env.GATSBY_STOREFRONT_ACCESS_TOKEN}',
							}, callback);
							console.log('Consent: Shopify Wrapped Script: ...did set');
						}).bind(window.Shopify.customerPrivacy);
					`}</script>
				}
				{isOsanoEnabled &&
					<script
						type={'text/javascript'}
						src={`https://cmp.osano.com/${process.env.GATSBY_OSANO_ACCOUNT_ID}/${process.env.GATSBY_OSANO_COOKIE_CONSENT_CONFIGURATION_ID}/osano.js`}
						data-flag-initialize-shopify-privacy-api
						onload={()=>{console.log('Consent Initialization: Osano Script Tag Loaded');}}
					/>
				}
				{isOsanoEnabled && isShopifyConsentTrackingApiEnabled &&
					<script type={'text/javascript'} data-flag-initialize-shopify-privacy-api>{`
						console.log('Consent Initialization: Inline Osano Script');
						try {
							window.Osano.cm.ready('shopify');
							console.log('Shopify Privacy API: initialized');
						} catch (error) {
							console.error('Osano must be loaded before initializing the Shopify API.');
							console.log('Shopify Privacy API: failed to initialize');
						}
					`}</script>
				}
				{false &&
					<script type={'text/javascript'} data-flag-initialize-shopify-privacy-api>{`
						window.addEventListener('DOMContentLoaded', function(event){
							const configurationOptions = {
								// analytics: true,
								// marketing: true,
								// preferences: true,
								// sale_of_data: true,
								headlessStorefront: true,
								checkoutRootDomain: '${process.env.GATSBY_DOMAIN_CHECKOUT}',
								storefrontRootDomain: '${process.env.GATSBY_DOMAIN_STOREFRONT}',
								storefrontAccessToken: '${process.env.GATSBY_STOREFRONT_ACCESS_TOKEN}',
							};

							console.log('Shopify Privacy API: initializing with:', {configurationOptions});

							window.Shopify.trackingConsent.setTrackingConsent(configurationOptions);
							window.Shopify.customerPrivacy.setTrackingConsent(configurationOptions);

							try {
								window.Osano.cm.ready('shopify');
								console.log('Shopify Privacy API: initialized');
							} catch (error) {
								console.error('Osano must be loaded before initializing the Shopify API.');
								console.log('Shopify Privacy API: failed to initialize');
							}
						}, false);
					`}</script>
				}
			</Helmet>
			<div className={rootClassnames}>
				<Header pathname={location.pathname} />
				<main>
					{children}
				</main>
				<Footer />
			</div>
			<CartFlyout />
			<div className={'select'} id={'select-portal'} />
		</>
	);
};

export default DefaultLayout;
