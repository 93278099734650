/* eslint-disable max-len */
// eslint-disable-next-line
import React, { useState } from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';

import { GatsbyImage } from 'gatsby-plugin-image';
import useFooterQuery from './useFooterQuery';

import Link from '../../components/Link';
import {
	Accordion,
	AccordionControl,
	AccordionItem,
	AccordionPanel
} from '../../components/Accordion';

import renderIcon from '../../utils/renderIcon';
import Newsletter from '../../components/Newsletter';
import { ButtonToTriggerPopup } from '../../components/CookieConsent/ButtonToTriggerPopup';

const ns = `site-footer`;

const Footer = () => {
	const {
		contentfulGlobalSettings: {
			logo,
		},
		// regionLinks: {
		// 	nodes: regionLinks,
		// },
		contentfulFooter,
	} = useFooterQuery();
	const {
		mailingListFormTitle, column1Title, column2Title, column1Links,
		column2Links, copyright, socialLinks, newsletterListId,
	} = contentfulFooter;
	const menuItems = [{title: column1Title, items: column1Links}, {title: column2Title, items: column2Links}];
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	// const [isCountrySelectorOpen, setIsCountrySelectorOpen] = useState(false);

	// const openCountrySelect = () => {
	// 	setIsCountrySelectorOpen(state => { return !state; });
	// };

	return (
		<div className={rootClassnames}>
			<div className={`${ ns }__main-container container-fluid`}>
				<div className={`${ ns }__left-content`}>
					<div className={`${ ns }__logo-container`}>
						{logo.image && logo.link && (
							<Link
								to={logo.link}
							>
								<GatsbyImage alt={logo.altText} image={logo?.image.gatsbyImageData} />
							</Link>
						)}
					</div>
					<Newsletter title={mailingListFormTitle} newsletterListId={newsletterListId} />
					<div className={`${ ns }__social-links`}>
						{socialLinks.map(socialLink => {
							return (
								<a
									key={uniqueId('pbg-')}
									href={socialLink.link}
								>
									<GatsbyImage image={socialLink.image?.gatsbyImageData} alt={socialLink.altText ?? socialLink.image?.description} />
								</a>
							);
						})}
					</div>
				</div>
				<div className={`${ ns }__right-content`}>
					<div className={`${ ns }__menu-desktop`}>
						{menuItems.map(menuItem => {
							return (
								<div className={`${ ns }__menu`} key={uniqueId('pbg-')}>
									<p className={`${ ns }__menu-title text-small font-medium`}>{menuItem.title}</p>
									{menuItem.items.length > 0 && (
										<ul>
											{menuItem.items.map(menuSubItem => {
												return (
													<li key={uniqueId('pbg-')}>
														<Link to={menuSubItem.url}>{menuSubItem.text}</Link>
													</li>
												);
											})}
										</ul>
									)}
								</div>
							);
						})}
					</div>
					<div className={`${ ns }__menu-mobile`}>
						<Accordion>
							{menuItems.map(menuItem => {
								return (
									<AccordionItem key={uniqueId('pbg-')}>
										<AccordionControl>
											<p className={`${ ns }__menu-title text-small`}>{menuItem.title}</p>
											<span>{renderIcon('cheveron-down')}</span>
										</AccordionControl>
										{menuItem.items.length > 0 && (
											<AccordionPanel>
												<div className={`${ ns }__menu-mobile-links`}>
													<ul>
														{menuItem.items.map(menuSubItem => {
															return (
																<li key={uniqueId('pbg-')}>
																	<Link to={menuSubItem.url}>{menuSubItem.text}</Link>
																</li>
															);
														})}
													</ul>
												</div>
											</AccordionPanel>
										)}
									</AccordionItem>
								);
							})}
						</Accordion>
					</div>
				</div>
			</div>
			<div className={`${ ns }__sub-container container-fluid`}>
				<p className={`${ ns }__copyright-text text-small`}>
					&copy;
					{' '}
					{copyright}
				</p>
				<p className={`${ ns }__copyright-text text-small`}>
					<ButtonToTriggerPopup />
				</p>
				{/* <div className={`${ ns }__country-select-wrapper`}>
					<div className={`${ ns }__country-select-options ${ isCountrySelectorOpen ? 'active' : '' }`}>
						<ul>
							{regionLinks.map(regionLink => {
								return (
									<li key={uniqueId('pbg-')}>
										<a href={regionLink.url}>
											{regionLink.title}
										</a>
									</li>
								);
							})}
						</ul>
					</div>
					<button className={`${ ns }__country-select ${ isCountrySelectorOpen ? 'active' : '' }`} onClick={openCountrySelect}>
						<p className={'text-small'}>United States</p>
						{renderIcon('cheveron-down')}
					</button>
				</div> */}
			</div>
		</div>
	);
};

export default Footer;
