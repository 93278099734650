/*	eslint-disable arrow-body-style */
import React from 'react';

const onClick = () => {
	window.Osano.cm.showDrawer();
};

export const ButtonToTriggerPopup = () => {
	return (
		<button className="ot-sdk-show-settings" type="button" onClick={onClick}>
			Do Not Sell or Share My Personal Information
		</button>
	)
};
